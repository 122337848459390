var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"blog-edit-wrapper"},[_c('div',{staticClass:"text-center"},[_c('h4',{staticClass:"mt-5"},[_vm._v(" Update Post / News "),_c('feather-icon',{attrs:{"icon":"Edit2Icon"}})],1)]),_c('validation-observer',{ref:"UpdateBlogFormvalidate"},[_c('b-form',{ref:"form",staticClass:"mt-2",staticStyle:{"width":"100%"},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Title","label-for":"blog-edit-title"}},[_c('validation-provider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"blog-edit-title"},model:{value:(_vm.UpdateBlogForm.title),callback:function ($$v) {_vm.$set(_vm.UpdateBlogForm, "title", $$v)},expression:"UpdateBlogForm.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Show In","label-for":"blog-edit-category"}},[_c('validation-provider',{attrs:{"name":"Show In","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"blog-edit-category","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"label","clearable":false,"options":_vm.showList,"reduce":function (val) { return val.id; }},on:{"input":_vm.getNews},model:{value:(_vm.UpdateBlogForm.type),callback:function ($$v) {_vm.$set(_vm.UpdateBlogForm, "type", $$v)},expression:"UpdateBlogForm.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Sub Title","label-for":"blog-edit-slug"}},[_c('validation-provider',{attrs:{"name":"Sub Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"blog-edit-slug"},on:{"input":function($event){return _vm.removeSpace($event)}},model:{value:(_vm.UpdateBlogForm.subtitle),callback:function ($$v) {_vm.$set(_vm.UpdateBlogForm, "subtitle", $$v)},expression:"UpdateBlogForm.subtitle"}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"In-Link Nam","label-for":"blog-edit-category"}},[_c('validation-provider',{attrs:{"name":"YouTube Link","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"videor_link"},on:{"input":function($event){return _vm.removeSpace($event)}},model:{value:(_vm.UpdateBlogForm.slug),callback:function ($$v) {_vm.$set(_vm.UpdateBlogForm, "slug", $$v)},expression:"UpdateBlogForm.slug"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.UpdateBlogForm.type==1)?_c('p',[_vm._v(" link after creation:"+_vm._s(("https://api.lmitac.com/articles/" + (_vm.UpdateBlogForm.slug))))]):_c('p',[_vm._v(" link after creation:"+_vm._s(("https://api.lmitac.com/news/" + (_vm.UpdateBlogForm.slug))))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"border rounded p-2"},[_c('h4',{staticClass:"mb-1"},[_vm._v(" Tab image ")]),_c('b-media',{staticClass:"flex-column flex-md-row",attrs:{"no-body":"","vertical-align":"center"}},[_c('div',{staticClass:"d-inline-block"},[_c('b-media-aside',[_c('b-img',{ref:"refPreviewE2",staticClass:"rounded mr-2 mb-1 mb-md-0",attrs:{"src":("https://api.lmitac.com/" + (_vm.UpdateBlogForm.tab_image)),"height":"110","width":"170"}})],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"rounded ml-1 mt-2 text-center",attrs:{"variant":"outline-secondary"},on:{"click":function($event){_vm.refPreviewE2.src = _vm.Tabimage
                            _vm.UpdateBlogForm.tab_image=''}}},[_vm._v(" Remove Image ")])],1),_c('b-media-body',[_c('b-card-text',{staticClass:"mt-1"}),_c('div',{staticClass:"d-inline-block  mt-1"},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Tab Image ","label-for":"blog-edit-title"}},[_c('validation-provider',{attrs:{"name":"Tab Image ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-file',{ref:"refInputE2",attrs:{"accept":".jpg, .png, .gif, .jpeg","placeholder":"Choose file"},on:{"input":_vm.inputImageRendererTab},model:{value:(_vm.UpdateBlogForm.tab_image),callback:function ($$v) {_vm.$set(_vm.UpdateBlogForm, "tab_image", $$v)},expression:"UpdateBlogForm.tab_image"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Tab Image Alt Text","label-for":"blog-edit-title"}},[_c('b-form-input',{attrs:{"id":"alt-image"},model:{value:(_vm.UpdateBlogForm.alt_tab_image),callback:function ($$v) {_vm.$set(_vm.UpdateBlogForm, "alt_tab_image", $$v)},expression:"UpdateBlogForm.alt_tab_image"}})],1)],1)],1)],1)],1),_c('div',{staticClass:"border rounded p-2"},[_c('h4',{staticClass:"mb-1"},[_vm._v(" Header image ")]),_c('b-media',{staticClass:"flex-column flex-md-row",attrs:{"no-body":"","vertical-align":"center"}},[_c('div',{staticClass:"d-inline-block"},[_c('b-media-aside',[_c('b-img',{ref:"refPreviewEl",staticClass:"rounded mr-2 mb-1 mb-md-0",attrs:{"src":("https://api.lmitac.com/" + (_vm.UpdateBlogForm.header_image)),"height":"110","width":"170"}})],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"rounded ml-1 mt-2 text-center",attrs:{"variant":"outline-secondary"},on:{"click":function($event){_vm.refPreviewEl.src = _vm.Tabimage
                            _vm.UpdateBlogForm.header_image=''}}},[_vm._v(" Remove Image ")])],1),_c('b-media-body',[_c('b-card-text',{staticClass:"mt-1"}),_c('div',{staticClass:"d-inline-block  mt-1"},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Header Image ","label-for":"blog-edit-title"}},[_c('validation-provider',{attrs:{"name":"Header Image ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-file',{ref:"refInputEl",attrs:{"accept":".jpg, .png, .gif, .jpeg","placeholder":"Choose file"},on:{"input":_vm.inputImageRenderer},model:{value:(_vm.UpdateBlogForm.header_image),callback:function ($$v) {_vm.$set(_vm.UpdateBlogForm, "header_image", $$v)},expression:"UpdateBlogForm.header_image"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Header image Alt Text","label-for":"blog-edit-title"}},[_c('b-form-input',{attrs:{"id":" alt_header_image"},model:{value:(_vm.UpdateBlogForm.alt_header_image),callback:function ($$v) {_vm.$set(_vm.UpdateBlogForm, "alt_header_image", $$v)},expression:"UpdateBlogForm.alt_header_image"}})],1)],1)],1)],1)],1)]),(_vm.UpdateBlogForm.type==1)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Article Category","label-for":"blog-edit-course"}},[_c('validation-provider',{attrs:{"name":"Courses","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"blog-edit-category","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"type","options":_vm.categoriesList,"reduce":function (val) { return val.id; }},on:{"input":function($event){return _vm.getCoursesByCategory(_vm.UpdateBlogForm.category_id)}},model:{value:(_vm.UpdateBlogForm.category_id),callback:function ($$v) {_vm.$set(_vm.UpdateBlogForm, "category_id", $$v)},expression:"UpdateBlogForm.category_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,493892931)})],1)],1):_vm._e(),(_vm.UpdateBlogForm.type==1)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Related Course","label-for":"blog-edit-course"}},[_c('validation-provider',{attrs:{"name":"Courses","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"blog-edit-category","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","options":_vm.coursesList,"multiple":"","reduce":function (val) { return val.id; }},scopedSlots:_vm._u([(!_vm.getCourse)?{key:"no-options",fn:function(ref){
                            var search = ref.search;
                            var searching = ref.searching;
                            var loading = ref.loading;
return [_vm._v(" Select Category First ... ")]}}:{key:"no-options",fn:function(ref){
                            var search = ref.search;
                            var searching = ref.searching;
                            var loading = ref.loading;
return [_vm._v(" Loading ... ")]}}],null,true),model:{value:(_vm.UpdateBlogForm.related_courses),callback:function ($$v) {_vm.$set(_vm.UpdateBlogForm, "related_courses", $$v)},expression:"UpdateBlogForm.related_courses"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2583798910)})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label-for":"blog-edit-course"}},[(_vm.UpdateBlogForm.type==1)?_c('label',[_vm._v(" Related Article")]):_c('label',[_vm._v(" Related News")]),_c('validation-provider',{attrs:{"name":"Courses","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"blog-edit-category","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","multiple":"","options":_vm.articleByCategoryList,"reduce":function (val) { return val.id; }},scopedSlots:_vm._u([(!_vm.getCourse)?{key:"no-options",fn:function(ref){
                            var search = ref.search;
                            var searching = ref.searching;
                            var loading = ref.loading;
return [_vm._v(" Select Category First ... ")]}}:{key:"no-options",fn:function(ref){
                            var search = ref.search;
                            var searching = ref.searching;
                            var loading = ref.loading;
return [_vm._v(" Loading ... ")]}}],null,true),model:{value:(_vm.UpdateBlogForm.related_posts),callback:function ($$v) {_vm.$set(_vm.UpdateBlogForm, "related_posts", $$v)},expression:"UpdateBlogForm.related_posts"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Details","label-for":"blog-content"}},[_c('validation-provider',{attrs:{"name":"Details","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('QuillEditor',{attrs:{"editorRef":'whatWeDo4Editor'},model:{value:(_vm.UpdateBlogForm.details),callback:function ($$v) {_vm.$set(_vm.UpdateBlogForm, "details", $$v)},expression:"UpdateBlogForm.details"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-50",attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":_vm.save}},[_vm._v(" Save Changes ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }